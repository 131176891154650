import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/post-card/post-card';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName } from './templates.style';

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext;
  const { posts: { edges } } = data;
  const totalCount = Array.isArray(edges) ? edges.length : Array(edges).length;
  return (
    <Layout>
      <SEO title={tag} description={`A collection of ${totalCount} post`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`A collection of ${totalCount} post`}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => {
          const tags = node.article_tags.map((article_tag: {tag_name: string}) => article_tag.tag_name);
          return (
              <PostCard
                key={node.slug}
                title={node.title}
                url={`/${node.slug}`}
                description={node.description}
                date={node.date}
                tags={tags}
                image={ node.coverImage == null
                  ? null
                  : node.coverImage.childImageSharp.fluid }
              />
          );
        })}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query fetchStrapiArticlesWithSpecificTag($tagRelated: StrapiArticleArticle_tagsFilterInput) {
    posts: allStrapiArticle(
      limit: 2000
      sort: { fields: [published_date], order: DESC }
      filter: {article_tags: {elemMatch: $tagRelated}}
      ) {
    totalCount
    edges {
      node {
        id
        slug
        title
        published_date
        coverImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        article_tags {
          tag_name
        }
      }
    }
    }
  }
`;
